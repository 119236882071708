import React from 'react';
import { Link } from 'react-router-dom';

import './Header.scss';
import FormPupLogo from '../../../images/form-pup-text-logo.png';

function Header({ openNav, userName, profileImageUrl }) {
  return (
    <div className="Header">
      <div className="header-left-container">
        <i onClick={openNav} className="fas fa-bars"></i>
        <Link to="/">
          <img
            // src="https://pupford.com/wp-content/uploads/2018/06/pupford-logo.png"
            src={FormPupLogo}
            alt=""
          />
        </Link>
      </div>

      <div className="user-info-container">
        <p className="user-name">Welcome{userName ? `, ${userName}` : ''}</p>

        <Link to="/account">
          <div className="profile-image-container">
            {!profileImageUrl ? <i className="fas fa-paw"></i> :
              <img src={profileImageUrl} alt="User"/>
            }
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Header;
