import { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';

import './Forms.scss';
import Modal from '../../shared/Modal';
import Loading from '../../shared/Loading';
import { config } from '../../../config';

const pathRegex = "^[A-Za-z0-9\-]+$";

function Forms({}) {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [folders, setFolders] = useState([]);
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [editFolder, setEditFolder] = useState({
    id: '',
    name: '',
    path: '',
    parentFolder: '',
    restrictAccess: false,
    allowedUsers: [],
    lastUpdatedBy: '',
    organizationId: '',
    created: 0,
    updated: 0,
    deleted: null,
  });

  useEffect(() => {
    if (!location.pathname.includes('/forms')) {
      history.replace('/forms');
      return;
    }

    if (location.pathname[location.pathname.length - 1] === '/') {
      history.replace(location.pathname.substring(0, location.pathname.length - 1));
    }

    fetchFiles();
  }, [location.pathname]);

  const fetchFiles = async () => {
    setLoading(true);

    try {
      const auth = getAuth();
      const token = await auth.currentUser.getIdToken(true);
      let parentFolder = '';

      if (location.pathname !== '/forms') {
        parentFolder = location.pathname.split('/').splice(2).join('/');
      }

      const folderResult = await axios.post(`${config.api}/v1/folders/get-by-parent-path`, {
        parentFolder,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const sortedFolders = folderResult.data.sort((a, b) => {
        return a.created - b.created;
      });

      setFolders(sortedFolders);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      let errorMessage = 'There was an error retrieving your forms. Please try again. If this problem persists, contact us for assistance.';
      if (e && e.response && e.response.data && e.response.data.message) {
        errorMessage = e.response.data.message;
      }

      setModalTitle('Error:');
      setModalText(errorMessage);
    }
  };

  const saveFolder = async () => {
    setLoading(true);

    try {
      const auth = getAuth();
      const email = auth.currentUser.email;
      const token = await auth.currentUser.getIdToken(true);
      let parentFolder = '';

      if (location.pathname !== '/forms') {
        parentFolder = location.pathname.split('/').splice(2).join('/');
      }

      let folderData = {
        parentFolder,
        path: `${parentFolder ? `${parentFolder}/` : ''}${editFolder.path}`,
        name: editFolder.name,
        restrictAccess: false,
        allowedUsers: [],
        lastUpdatedBy: email,
      };

      const result = await axios.post(`${config.api}/v1/folder`, {
        ...folderData,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setFolders([ ...folders, result.data ]);
      setLoading(false);
      closeFolderModal();
    } catch (e) {
      setLoading(false);
      let errorMessage = 'There was an error saving your folder. Please try again. If this problem persists, contact us for assistance.';
      if (e && e.response && e.response.data && e.response.data.message) {
        errorMessage = e.response.data.message;
      }

      setModalTitle('Error:');
      setModalText(errorMessage);
    }
  };

  const closeFolderModal = () => {
    setShowFolderModal(false);
    setEditFolder({
      id: '',
      name: '',
      path: '',
      parentFolder: '',
      restrictAccess: false,
      allowedUsers: [],
      lastUpdatedBy: '',
      organizationId: '',
      created: 0,
      updated: 0,
      deleted: null,
    });
  };

  const renderBreadcrumbs = () => {
    const pathArr = location.pathname.split('/');
    pathArr.shift();

    return (
      <>
        {pathArr.map((path, i) => {
          if (i === pathArr.length - 1) {
            return (
              <div className="breadcrumb-item-container" key={`breadcrumb-item-${i}`}>
                <span className="breadcrumb-item">{path}</span>
              </div>
            );
          }

          return (
            <div className="breadcrumb-item-container" key={`breadcrumb-item-${i}`}>
              <Link to={`/${pathArr.slice(0, i + 1).join('/')}`} className="breadcrumb-item">{path}</Link>
              <div className="breadcrumb-item">/</div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="Forms">
      {!loading ? null : <Loading />}

      <div className="breadcrumbs-container">
        {location.pathname === '/forms' ?
          <div className="breadcrumb-item-container">
            <span className="breadcrumb-item">Home Folder</span>
          </div> :
          renderBreadcrumbs()
        }
      </div>

      <div className="top-row">
        <div className="header-container">
          <h1>Forms</h1>
        </div>

        <div className="buttons-container">
          <Link to="/form-builder">
            <button className="gradient">
              + Create Form
            </button>
          </Link>

          <button onClick={() => setShowFolderModal(true)}>
            + Add Folder
          </button>
        </div>
      </div>

      <div className="items-container">
        {folders.map(folder => {
          return (
            <Link to={`/forms/${folder.path}`} key={folder.id} className="item-container">
              <div className="item-container-inner">
                <div className="item-image">
                  <i className="fas fa-folder-open"></i>
                </div>
                <div className="item-name">{folder.name}</div>
              </div>
            </Link>
          );
        })}
      </div>

      <Modal
        open={showFolderModal}
        title={`${editFolder.id ? 'Edit' : 'Create'} Folder`}
        close={closeFolderModal}
        buttons={[
          <button
            key="modal-save"
            className="small gradient"
            disabled={!editFolder.name || !editFolder.path || !editFolder.path.match(pathRegex)}
            onClick={saveFolder}
          >
            Save Folder
          </button>,
          <button
            key="modal-close"
            className="small"
            onClick={closeFolderModal}
          >
            Cancel
          </button>,
        ]}
      >
        <div className="edit-folder-modal">
          <div className="input-container">
            <label>Folder Name:</label>
            <input
              type="text"
              value={editFolder.name}
              onChange={(e) => {
                setEditFolder({
                  ...editFolder,
                  name: e.target.value,
                });
              }}
              placeholder="Folder Name"
            />
          </div>

          <div className="input-container">
            <label>Folder Path:</label>
            <input
              type="text"
              value={editFolder.path}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9a-z\-]/gi, '');
                setEditFolder({
                  ...editFolder,
                  path: value
                });
              }}
              placeholder="Folder Path"
            />
            <small>Only letters, number, and hyphens allowed</small>
          </div>
        </div>
      </Modal>

      <Modal
        open={!!modalText}
        close={() => {
          setModalTitle('');
          setModalText('');
        }}
        title={modalTitle}
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            setModalTitle('');
            setModalText('');
          }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default Forms;
