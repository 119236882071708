import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import './Sidebar.scss';

const links = [
  {
    route: '/',
    name: 'Forms',
    iconClassName: 'fas fa-columns',
  },
  {
    route: '/team',
    name: 'Team',
    iconClassName: 'fas fa-users',
  },
  {
    route: '/analytics',
    name: 'Analytics',
    iconClassName: 'fas fa-chart-area',
  },
  {
    route: '/account',
    name: 'Account',
    iconClassName: 'fas fa-user',
  },
];

function Sidebar({
  closeSidebar,
  logOut,
}) {
  let location = useLocation();

  const getActiveClassName = (link) => {
    if (location.pathname.includes('/forms')) {
      return link.name === 'Forms' ? ' active' : '';
    }

    return location.pathname === link.route ? ' active' : '';
  };

  return (
    <div className="Sidebar">
      {links.map(link => {
        return (
          <Link to={link.route} key={link.route}>
            <div
              onClick={closeSidebar}
              className={`nav-item${getActiveClassName(link)}`}
            >
              <i className={link.iconClassName}></i>
              {link.name}
            </div>
          </Link>
        );
      })}
      <a>
        <div className="nav-item sign-out" onClick={logOut}>
          <i className="fas fa-sign-out-alt"></i>
          Sign Out
        </div>
      </a>
    </div>
  );
}

export default Sidebar;
