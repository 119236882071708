import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from 'firebase/auth';
import { Link, Route, Switch } from 'react-router-dom';
import axios from 'axios';

import './Dashboard.scss';
import FormPupLogo from '../../images/form-pup-text-logo.png';
import Forms from './Forms';
import Header from '../layout/Header';
import Sidebar from '../layout/Sidebar';
import Loading from '../shared/Loading';
import Modal from '../shared/Modal';
import { config } from '../../config';
import { setOrganization, updateUser } from '../../store/actions';

function Dashboard({}) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [navClosed, setNavClosed] = useState(true);
  const [loading, setLoading] = useState(true);
  const [userErrorModalOpen, setUserErrorModalOpen] = useState(false);

  useEffect(() => {
    fetchUserAndOrganization();
  }, []);

  const fetchUserAndOrganization = async () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    let token = '';

    try {
      token = await currentUser.getIdToken(true);
    } catch (e) {
      auth.signOut();
      return;
    }

    const uid = currentUser.uid;

    try {
      const response = await axios.get(`${config.api}/v1/user/${uid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const userData = response.data;
      
      if (!userData.id) {
        createOrganizationAndUserEntry(uid, token);
        return;
      }

      const organizationResponse = await axios.get(`${config.api}/v1/organization/${userData.organizationId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const organizationData = organizationResponse.data;

      dispatch(updateUser(userData));
      dispatch(setOrganization(organizationData));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setUserErrorModalOpen(true);
    }
  };

  const createOrganizationAndUserEntry = async (id, token) => {
    try {
      const response = await axios.post(`${config.api}/v1/organization`, {
        organizationOwner: id,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch(updateUser(response.data.user || {}));
      dispatch(setOrganization(response.data.organization || {}));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setUserErrorModalOpen(true);
    }
  };

  const closeUserErrorModal = () => {
    const auth = getAuth();
    auth.signOut();
  };

  return (
    <div className="Dashboard">
      {!loading ? null : <Loading />}
      <Header
        profileImageUrl={(user.profileImage && user.profileImage.url) || ''}
        userName={`${user.firstName ? user.firstName : ''}`}
        openNav={() => setNavClosed(false)}
      />

      <div className="main-container">
        <div className="nav-container">
          <div className={`nav-inner${navClosed ? ' closed' : ''}`}>
            <div className="logo-container">
              <i onClick={() => setNavClosed(true)} className="fas fa-times close"></i>
              <Link to="/">
                <img
                  className="logo"
                  // src="https://pupford.com/wp-content/uploads/2018/06/pupford-logo.png"
                  src={FormPupLogo}
                  alt=""
                />
              </Link>
            </div>

            <Sidebar
              logOut={() => {
                const auth = getAuth();
                auth.signOut();
              }}
              closeSidebar={() => setNavClosed(true)}
            />
          </div>
        </div>

        <div className="content-container">
          <div className="content-inner">
            <Switch>
              <Route path="/forms" component={Forms} />
              <Route path="/" component={Forms} />
              <Route component={Forms} />
            </Switch>
          </div>
        </div>
      </div>

      <Modal
        open={userErrorModalOpen}
        close={closeUserErrorModal}
        title="Error:"
        buttons={[
          <button
            key="modal-close"
            className="small"
            onClick={closeUserErrorModal}
          >
            Close
          </button>,
        ]}
      >
        <div>
          <div className="modal-text">There was an error retrieving your account settings. Please sign in again.</div>
        </div>
      </Modal>
    </div>
  );
}

export default Dashboard;
