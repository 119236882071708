import './FormBuilder.scss';

function FormBuilder({}) {
  return (
    <div className="FormBuilder">
      FormBuilder
    </div>
  );
}

export default FormBuilder;
