import { Switch, Route } from 'react-router-dom';

import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

function Auth({}) {
  return (
    <div className="Auth">
      <Switch>
        <Route path="/signin" component={SignIn} />
        <Route path="/signup" component={SignUp} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route component={SignIn} />
      </Switch>
    </div>
  );
}

export default Auth;
