import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Main from './components';

const history = createBrowserHistory();

function App() {
  return (
    <div className="App">
      <Router history={history}>
        <Main></Main>

        <div id="modalTarget"></div>
      </Router>
    </div>
  );
}

export default App;
