import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import './ForgotPassword.scss';
import { config } from '../../../config';
import Modal from '../../shared/Modal';
import FormPupLogo from '../../../images/form-pup-text-logo.png';

function ForgotPassword(props) {
  const [email, setEmail] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [loading, setLoading] = useState(false);

  const submit = async (e) => {
    e.preventDefault();

    setLoading(true);

    if (!email) {
      setLoading(false);
      setModalTitle('Notice:');
      setModalText('The email address provided is not valid. Please provide a valid email address.');
      return;
    }

    try {
      const response = await axios.post(`${config.api}/api/v1//user/send-reset-password-link`, {
        email: email.trim().toLowerCase(),
      });
      const data = response.data;

      if (data.status === 'error') {
        setModalTitle('Error:');
        setModalText(data.message);
      } else {
        setModalTitle('Success!');
        setModalText(`A link to reset your password has been sent to ${email} and should be arriving shortly. If it does not arrive in your inbox, check your spam folder.`);
        setEmail('');
      }
    } catch (e) {
      let errorMessage = 'There was an error processing your password reset request. Please try again. If this problem persists, contact us for assistance.';
      if (e && e.response && e.response.data && e.response.data.message) {
        errorMessage = e.response.data.message;
      }

      setModalTitle('Error:');
      setModalText(errorMessage);
    }

    setLoading(false);
  };

  return (
    <div className="ForgotPassword">
      <div className="form-container">
        <div className="well auth-well">
          <div className="logo-container">
            <img
              src={FormPupLogo}
              alt="Form Pup Logo"
              className="logo"
            />
          </div>

          <h1>Forgot Password</h1>

          <p className="forgot-password-message">
            Lost your password? Please enter your email address below. You will receive a link to create a new password via email.
          </p>

          <form onSubmit={submit}>
            <div className="input-container">
              <label>Email:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email"
                disabled={loading}
              />
            </div>

            <button
              type="submit"
              disabled={loading}
            >
              Submit
            </button>
          </form>

          <div className="sign-in-container">
            <span>Already know your password? </span>

            <Link to="/signin">
              Sign In
            </Link>
          </div>
        </div>
      </div>

      <Modal
        open={!!modalText}
        close={() => {
          setModalTitle('');
          setModalText('');
        }}
        title={modalTitle}
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            setModalTitle('');
            setModalText('');
          }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default ForgotPassword;
